import { graphql } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"
// import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Hero from "../components/hero"
import ListenSub from "../components/listen-sub"
import Explainer from "../components/explainer"
import HowItWorks from "../components/how-it-works"
import JFKSpeech from "../components/jfk-speech"
import JFKVideo from "../components/jfk-video"

const ListenPage = ({ data }) => {
  // window.pageYOffset = 0
  // window.setTimeout(() => {
  //   window.scrollTo(0, 0)
  // }, 0)

  return (
    <Layout id="listen">
      <Helmet title="Listen | JFK We Choose 2" />
      <Hero {...data.strapiListen.hero} isAnimated={true} />
      <ListenSub {...data.strapiListen.listen} />
      <JFKSpeech />
      <HowItWorks teaser={data.strapiListen.teaser} />
      <JFKVideo />
      <Explainer {...data.strapiListen.explainer} />

      {/* <div  style={{margin: '150px 0', textAlign: 'center'}}>
        <div>VIDEO I THINK WE'RE KILLING</div>
        <button>
          <StaticImage src="../images/icon-play.png" alt="Play" objectFit="contain" placeholder={"none"} />
          <div>Learn</div>
        </button>
      </div> */}
    </Layout>
  )
}

export default ListenPage

export const query = graphql`
{
  strapiListen {
    hero {
      title
      description
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
            )
          }
        }
      }
    }
    teaser {
      id
      title
      description {
        data {
          description
        }
      }
      ctalabel
      ctalink
    }
    explainer {
      title
      copy {
        data {
          copy
        }
      }
      quote1
      quote2
    }
    listen {
      title
      date1
      date2
      subhead
      quote
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      imageDesc
    }
  }
}`;
